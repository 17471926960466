import React, { Component } from "react";
//import { NavLink, Redirect } from "react-router-dom";Commented because not in use
import close_btn from '../../../images/close_btn.svg';
import { userService } from "../../../_services";
import $ from 'jquery';
class AddUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            login_page: false,
            Fname: '',
            Lname: '',
            Phone: '',
            Email: '',
            isuserpass: false,
            Username: '',
            Password: '',
            extra_info: false,
            extra_info_msg: '',
            login_loader: false,
            submit_msg: '',
            saveloader: false,
            errors: {
                Fname_E: '',
                Lname_E: '',
                Phone_E: '',
                Email_E: '',
                Username_E: '',
                Password_E: ''
            }
        };
        this.noerrors = {
            Fname_E: '',
            Lname_E: '',
            Phone_E: '',
            Email_E: '',
            Username_E: '',
            Password_E: ''
        }
        this.handle_save_continue_btn = this.handle_save_continue_btn.bind(this);
        this.removeError = this.removeError.bind(this);
        this.dofocus = this.dofocus.bind(this);
    }
    componentDidMount() {
        $('body').css('overflow-y', 'hidden');
    }
    handle_save_continue_btn(event) {
        event.preventDefault();
        let errors = this.state.errors;
        try {
            userService.GetPhoneNoValidate(this.state.Phone)
                .then(apidata => {
                    if (apidata[0].code !== "1") {
                        errors.Phone_E = 'Invalid Phone Number';
                    }
                    else {
                        if (apidata[0].message != null) {
                            this.setState({ Phone: apidata[0].message })
                        }
                    }
                    
        if (this.state.Fname.length < 3)
            errors.Fname_E = 'First Name must be at least 3 characters long';
        if (this.state.Lname.length < 2)
            errors.Lname_E = 'Last Name must be at least 2 characters long';
        //if (userService.ValidPhone(this.state.Phone) == false && this.state.Phone != "")
        //    errors.Phone_E = 'Please enter valid Phone Number';
        if (this.state.Phone.replace(/[- )(]/g, '').length < 10 || this.state.Phone.replace(/[- )(]/g, '').length > 10) {
           errors.Phone_E = 'Phone Number must be 10 digits';
          }
        if (this.state.Email.length < 1)
            errors.Email_E = "Enter your email";
        if (userService.ValidEmailRegex(this.state.Email) == false && this.state.Email != "")
                        errors.Email_E = 'Invalid Email';
                    if (this.state.isuserpass !== false) {
                        if (this.state.Username.length < 5)
                            errors.Username_E = 'Username must be at least 5 characters long';
                        if (this.state.Username.includes(' '))
                            errors.Username_E = 'Username does not allow space';
                        if (this.state.Password.length < 6)
                            errors.Password_E = 'Password must be at least 6 characters long';
                    }
        if (JSON.stringify(errors) !== JSON.stringify(this.noerrors))
            this.setState({ first_step_signup: false, errors: errors });
        else
            this.Submit_AddNewUser(this.state.Fname, this.state.Lname, this.state.Phone, this.state.Email, this.state.Username, this.state.Password);
                })
        }
        catch (error) {
        }
    }
    keyPress = (e) => {
        if (e.keyCode === 13) {
            let errors = this.state.errors;
            if (this.state.Fname.length < 3)
                errors.Fname_E = 'First Name must be at least 3 characters long';
            if (this.state.Lname.length < 2)
                errors.Lname_E = 'Last Name must be at least 2 characters long';
            if (this.state.Email.length < 1)
                errors.Email_E = "Enter your email";
            if (userService.ValidEmailRegex(this.state.Email) === false && this.state.Email !== "")
                errors.Email_E = 'Invalid Email';
            if (this.state.Username.length < 1)
                errors.Username_E = "Enter your username";
            if (this.state.Password.length < 1)
                errors.Password_E = "Enter your password";
            if (JSON.stringify(errors) !== JSON.stringify(this.noerrors))
                this.setState({ first_step_signup: false, errors: errors });
            else
                this.Submit_AddNewUser(this.state.Fname, this.state.Lname, this.state.Phone, this.state.Email, this.state.Username, this.state.Password);
        }
    }
    HandleInput = (event) => {
        let errors = this.state.errors;

        if (event.target.name === 'Phone') {
            if (event.target.value !== "") {
                var regex = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
                if (event.target.value.match(regex)) {
                    this.setState({ [event.target.name]: event.target.value, extra_info: false, extra_info_msg: '' });
                    errors[event.target.name + "_E"] = '';
                    this.setState({ errors: errors });
                }
                else {
                    this.setState({ [event.target.name]: '', extra_info: false, extra_info_msg: '' });
                }
            }
            else {
                this.setState({ [event.target.name]: event.target.value, extra_info: false, extra_info_msg: '' });
            }
        }
        if (event.target.name === "Fname" || event.target.name === "Lname") {
            if (event.target.value !== "") {
                var special = /^[a-zA-Z]+$/g;
                if (event.target.value.match(special)) {
                    this.setState({ [event.target.name]: event.target.value, extra_info: false, extra_info_msg: '' });
                    errors[event.target.name + "_E"] = '';
                    this.setState({ errors: errors });
                }
                else {
                    return false;
                }
            }
            else {
                this.setState({ [event.target.name]: event.target.value, extra_info: false, extra_info_msg: '' });
            }
        }
        if (event.target.name === "CreateUserPass") {
            this.setState({ isuserpass: !this.state.isuserpass })
        }
        else {
            this.setState({ [event.target.name]: event.target.value, extra_info: false, extra_info_msg: '' });
            errors[event.target.name + "_E"] = '';
            this.setState({ errors: errors });
        }
    }
    removeError = (event) => {
        let errors = this.state.errors;
        errors[event.target.previousElementSibling.previousElementSibling.name + "_E"] = '';
        this.setState({ errors: errors });
    }
    dofocus = (event) => {
        event.target.previousElementSibling.focus();
    }
    Validate_UserData(Phone, Email, UserName, IsUserName) {
        this.setState({ login_loader: true });
        try {
            userService.CheckAvailability(Phone, Email, UserName, IsUserName)
                .then(apidata => {

                }
                );
        }
        catch (error) {
        }
    }
    handleResponse(response) {
        return response.text().then(text => {
            try {
                const data = text && JSON.parse(text);
                return data;
            }
            catch (error) {
                const data = [];
                return data;
            }
        });
    }

    handleSaveSubmit = (e) => {
        try {
            this.Submit_AddNewUser(this.state.Fname, this.state.Lname, this.state.Phone, this.state.Email, this.state.Username, this.state.Password);
        }
        catch (error) { }
    }
    Submit_AddNewUser(Fname, Lname, Phone, Email,UserName,Password) {
        this.setState({ saveloader: true })
        this.setState({ submit_msg: '' });
        try {
            if (this.state.isuserpass !== false) {
                userService.CreateNewUserByAdmin(Fname, Lname, Phone, Email, UserName, Password)
                    .then(
                        apidata => {
                            if (apidata.length > 0) {
                                if (apidata[0].code === '1') {
                                    this.setState({ submit_msg: apidata[0].message, saveloader: false });
                                }
                                else {
                                    this.setState({ submit_msg: apidata[0].message, saveloader: false });
                                }
                            }
                            else {
                                this.setState({ submit_msg: 'Please try later', saveloader: false });
                            }
                        })
            }
            else {
                userService.AddNewUser(Fname, Lname, Phone, Email)
                .then(
                    apidata => {
                        if (apidata.length > 0) {
                            if (apidata[0].code === '1') {
                                this.setState({ submit_msg: apidata[0].message, saveloader: false });
                                setTimeout(() => {
                                    this.props.adduserclose();
                                }, 1000)
                            }
                            else {
                                this.setState({ submit_msg: apidata[0].message, saveloader: false });
                                // this.setState({ save_loader: false, submit_msg: 'Something went Wrong!' });
                            }
                        }
                        else {
                            this.setState({ submit_msg: 'Please try later', saveloader: false });
                            // this.setState({ save_loader: false, submit_msg: 'Something went Wrong!' });
                        }
                    }

                );
            }
        }
        catch (error) {
            this.setState({ submit_msg: 'Please try later', saveloader: false });
        }
    }
    render() {
        const { errors } = this.state;
        return (
            <div className="add_url_pop new_user_popup">
                <div className="signuparea">
                    <div className="header">
                        Add User
                        <div className="close" onClick={this.props.adduserclose}>
                            <img src={close_btn} alt=""/>
                        </div>
                    </div>

                    <div className="body fields addusers_fields">
                        <h3>&nbsp;</h3>
                        <div className={errors.Fname_E.length > 0 ? 'errors' : ''}>
                            <input className="test" type="text" placeholder=" " name="Fname" value={this.state.Fname} onKeyDown={this.keyPress} onChange={this.HandleInput} onFocus={this.HandleInput} />
                            <span onClick={this.dofocus.bind(this)}>First Name</span>
                            {errors.Fname_E.length > 0 &&
                                <span onClick={this.removeError.bind(this)}>{errors.Fname_E} </span>}
                        </div>
                        <div className={errors.Lname_E.length > 0 ? 'errors' : ''}>
                            <input type="text" placeholder=" " name="Lname" value={this.state.Lname} onKeyDown={this.keyPress} onChange={this.HandleInput} onFocus={this.HandleInput} />
                            <span onClick={this.dofocus.bind(this)}>Last Name</span>
                            {errors.Lname_E.length > 0 &&
                                <span onClick={this.removeError.bind(this)}>{errors.Lname_E}</span>}
                        </div>
                        <div className={errors.Phone_E.length > 0 ? 'errors' : ''}>
                            <input type="text" placeholder=" " name="Phone" value={this.state.Phone} onKeyDown={this.keyPress} onChange={this.HandleInput} onFocus={this.HandleInput} />
                            <span onClick={this.dofocus.bind(this)}>Phone Number</span>
                            {errors.Phone_E.length > 0 &&
                                <span className="error" onClick={this.removeError.bind(this)}>{errors.Phone_E}</span>}
                        </div>
                        <div className={errors.Email_E.length > 0 ? 'errors' : ''}>
                            <input type="email" placeholder=" " name="Email" value={this.state.Email} onKeyDown={this.keyPress} onChange={this.HandleInput} onFocus={this.HandleInput} />
                            <span onClick={this.dofocus.bind(this)}>Email</span>
                            {errors.Email_E.length > 0 &&
                                <span className="error" onClick={this.removeError.bind(this)}>{errors.Email_E}</span>}
                        </div>
                        <div className='userchk'>
                            <input type="checkbox" name="CreateUserPass" id="CreateUserPass" value="CreateUserPass" checked={this.state.isuserpass} onChange={this.HandleInput} />
                            <label htmlFor="CreateUserPass">Create Username/Password</label>
                        </div>
                        {this.state.isuserpass ? <>
                            <div className={errors.Username_E.length > 0 ? 'errors' : ''}>
                                <input type="text" placeholder=" " name="Username" value={this.state.Username} onKeyDown={this.keyPress} onChange={this.HandleInput} onFocus={this.HandleInput} />
                                <span onClick={this.dofocus.bind(this)}>Username</span>
                                {errors.Username_E.length > 0 &&
                                    <span className="error" onClick={this.removeError.bind(this)}>{errors.Username_E}</span>}
                            </div>
                            <div className={errors.Password_E.length > 0 ? 'errors' : ''}>
                                <input type="password" placeholder=" " name="Password" value={this.state.Password} onKeyDown={this.keyPress} onChange={this.HandleInput} onFocus={this.HandleInput} />
                                <span onClick={this.dofocus.bind(this)}>Password</span>
                                {errors.Password_E.length > 0 &&
                                    <span className="error" onClick={this.removeError.bind(this)}>{errors.Password_E}</span>}
                            </div>
                        </>
                        : null}
                        {this.state.extra_info === true ?
                            <div className="extra_info">
                                {this.state.extra_info_msg}
                            </div>
                            : null}
  
                        
                    </div>

                    <div className="footer">
                        <div className={this.state.saveloader ? "yes_btn loader" : "yes_btn" } onClick={this.handle_save_continue_btn.bind(this)}>Add User</div>
                        <div className="no_btn" onClick={this.props.adduserclose}>Cancel</div>
                        {this.state.submit_msg.length ?
                            <div className="error_submit_msg">{this.state.submit_msg}</div>
                            : null}
                    </div>
                </div>

            </div>
        );
    }
}
export default AddUser;