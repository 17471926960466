import React, { Component } from 'react';
import $ from 'jquery';
import { userService } from "../../../_services";
import { Redirect } from "react-router-dom";
import Left_Sidebar from '../../After_Login_common/Left_Sidebar';
import Header from '../../After_Login_common/Header';
import Topmenu from "../Topmenu";
import UserDashboard from "../user/Dashboard";

class Transactions extends Component {
    constructor(props) {
        super(props);
        var startdate = new Date();
        startdate.setDate(startdate.getDate() - 30);
        var enddate = new Date();
        if (localStorage.getItem('User_Start_Date') != "" && localStorage.getItem('User_Start_Date') != null && localStorage.getItem('User_Start_Date') != undefined) {
            startdate = localStorage.getItem('User_Start_Date');
        }
        if (localStorage.getItem('User_End_Date') != "" && localStorage.getItem('User_End_Date') != null && localStorage.getItem('User_End_Date') != undefined) {
            enddate = localStorage.getItem('User_End_Date');
        }
        let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
        this.state = {
            dashboardListRendered: false,
            dashboardName: Usermeta_Data.split(':')[1],
            shortname: '',
            fullName: '',
            startDate: startdate,
            endDate: enddate,
            userdata: localStorage.getItem('ajs_user_id'),
            AllDirectOverride: 'Direct',
            dist_usertype: 'N/A',
            UserId: '',
            UserName: '',
        }
        this.dashboardListRendered = this.dashboardListRendered.bind(this);
        this.viewdashboard = this.viewdashboard.bind(this);
    }
    componentDidMount() {
        let usernamedata = window.atob(localStorage.getItem('hacker_user_detail'));
        this.setState({ shortname: usernamedata.split(':')[0], fullName: usernamedata.split(':')[1] });
        let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
        this.setState({ UserId: Usermeta_Data.split(":")[0] });
        this.setState({ UserName: Usermeta_Data.split(":")[1] });
        $('body').addClass('transaction');
    }
    componentWillUnmount() {
        $('body').removeClass('transaction');
    }
    handleChangeStartEnd = (d) => {
        try {
            this.setState({
                ...this.state,
                startDate: d.split('-')[0],
                endDate: d.split('-')[1]
            });
        }
        catch (error) {
        }
    }
    dashboardListRendered(e) {
        try {
            this.setState({
                ...this.state,
                dashboardListRendered: true
            })
        }
        catch (error) { }
        this.dashboardListRendered = this.dashboardListRendered.bind(this);
    }
    handleChangeAllDirectOverride = (e) => {
        try {
            this.setState({
                ...this.state,
                userdata: localStorage.getItem('ajs_user_id'),
                AllDirectOverride: e
            });
        }
        catch (error) {
        }
    }
    handeChangefilter = (e) => {
        this.setState({
            ...this.state,
            userdata: localStorage.getItem('ajs_user_id')
        });
        if (e != undefined) {
            let cls_name = e.currentTarget.className;
            let event_type = e.type;
            if (cls_name.includes('reset_filter_tag_button_event') && event_type == 'click') {
                document.getElementById('totalpaid1').click();
            }
            if (localStorage.getItem('Updated_HD_Type') =='totalpaid') {
                document.getElementById('totalpaid1').click();
            }
        }
    }
    viewdashboard() {
        let userid = this.state.UserId;
        let username = this.state.UserName
        try {
            userService.claimdashboard(userid, username)
                .then(
                    apidata => {
                        if (apidata.length > 0) {
                            if (apidata[0].code !== "-1") {
                                let getlogin = window.location.href;
                                var wparam = localStorage.getItem('wt19:awaydpset');
                                if (getlogin.toLowerCase().includes("release-backoffice") || getlogin.toLowerCase().includes("dev-backoffice") || getlogin.toLowerCase().includes("localhost:")) {
                                    window.open('http://dev.glichealth.com/login?q=' + wparam, '_blank');
                                }
                                else {
                                    window.open('https://reporting.nulifespanrx.com/login?q=' + wparam, '_blank');
                                }
                            }
                        }
                        else {
                            this.setState({ submit_msg: 'Something went wrong.Please try later' });
                        }
                    }
                );
        }
        catch {
        }
    }
    render() {
        if (userService.Check_Null_Empty(localStorage.getItem("ajs_user_id")) === true) return <Redirect to="/signup" />
        localStorage.setItem('pagename', 'claimdashboard');

        return (
            <div>
                <div className="Dashboard_Area">
                    <Left_Sidebar Activemenu="9" fullName={this.state.fullName} />
                    <div className={this.state.group_loader ? "contentarea none_event" : "contentarea"}>
                        <Header shortName={this.state.shortname} fullName={this.state.fullName} handleSearchFilter={this.handleSearchFilter} userRole="" />
                        <div className="content_sec admin_sec">
                            <h2 className="claim_header">Claims Dashboard</h2>
                            <span className="claim_subheading" onClick={this.viewdashboard.bind(this)}>Miss the old reporting dashboard? You can still access it here.</span>
                            <Topmenu handeChangefilter={this.handeChangefilter} dashboardListRendered={this.dashboardListRendered} handleChangeDateNew={this.handleChangeStartEnd} handeChangefilter={this.handeChangefilter} dashboardTitle={this.state.dashboardTitle} startDate={this.state.startDate} endDate={this.state.endDate} handleChangeAllDirectOverride={this.handleChangeAllDirectOverride} />
                            {
                                this.state.dashboardListRendered &&
                                <this.RenderDashboard dashboardProps={this.state} handelchange={this.handeChangefilter} handlealldirectoverride={this.handleAllDirectOverride_user} />
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    RenderDashboard(props) {
        if (!!props && !!props.dashboardProps) {
            try {
                return <UserDashboard dist_usertype={props.dashboardProps.AllDirectOverride} dashboardName="saa" handeChangefilter={props.handelchange} handleAllDirectOverride_user={props.handlealldirectoverride} startDate={props.dashboardProps.startDate} endDate={props.dashboardProps.endDate} />
            }
            catch (error) {
                return <UserDashboard dist_usertype={props.dashboardProps.AllDirectOverride} dashboardName="saa" handeChangefilter={props.handelchange} handleAllDirectOverride_user={props.handlealldirectoverride} startDate={props.dashboardProps.startDate} endDate={props.dashboardProps.endDate} />
            }
        }
    }
}
export default Transactions;