import React, { Component } from "react"; //, FC, useState, useEffect, useCallback Commented because not in use
import Signup_header from "../Register/signup_header";
import Signupleft from "../../images/signupleft.png";
import { userService } from "../../_services";
import { Redirect, NavLink } from 'react-router-dom';//, Router Commented because not in use
import mobile_login_bg from "../../images/signup_bg.png";
import Cookies from 'universal-cookie';
import $ from 'jquery';

class LogIn extends Component {
    constructor(props) {
        super(props);
        let uname = '', pwd = '';
        try {
            const cookies = new Cookies();
            var CryptoJS = require("crypto-js");
            var bytes_un = CryptoJS.AES.decrypt(cookies.get('_col_uuid'), 'sblw3hn8');
            var bytes_pwd = CryptoJS.AES.decrypt(cookies.get('__gads'), 'sblw3hn8');
            uname = bytes_un.toString(CryptoJS.enc.Utf8);
            pwd = bytes_pwd.toString(CryptoJS.enc.Utf8);
        }
        catch{ }
        this.state = {
            IsUserLogin: false,
            UserName: uname,
            Password: pwd,
            errors: {
                UserName_E: '',
                Password_E: ''
            },
            isRemember: false,
            login_loader: false,
            User_role: ''
        };
        this.noerrors = {
            UserName_E: '',
            Password_E: ''
        }
        this.HandleButtonClick = this.HandleButtonClick.bind(this);
        this.showpassword = this.showpassword.bind(this);
        this.removeError = this.removeError.bind(this);
        this.dofocus = this.dofocus.bind(this);

    }
    componentDidMount() {
        let pval = this.props;
        try {
            if (!userService.Check_Null_Empty(pval.location.search)) {
                this.callForTokenAPI(pval.location.search.replace('?code=', ''));
            }
        }
        catch (error) { }

    }
    HandleInput = (event) => {
        let errors = this.state.errors;
        this.setState({ [event.target.name]: event.target.value });
        errors[event.target.name + "_E"] = '';
        this.setState({ errors: errors });
    }
    HandleButtonClick = (event) => {
        event.preventDefault();
        let errors = this.state.errors;
        if (userService.Check_Null_Empty(this.state.UserName))
            errors.UserName_E = 'Please enter Valid Username';
        if (userService.Check_Null_Empty(this.state.Password))
            errors.Password_E = 'Please enter Password';
        if (JSON.stringify(errors) !== JSON.stringify(this.noerrors))
            this.setState({ errors: errors });
        else
            this.MakeLogin(this.state.UserName, this.state.Password);
    }

    MakeLogin(UserName, Password) {
        this.setState({ login_loader: true });
        //this.getjwt(UserName, Password);
        userService.login(UserName, Password)
            .then(
                apidata => {
                    try {
                        if (apidata.length > 0) {
                            if (apidata[0].code !== "-1") {
                                //window.localStorage.setItem('UserRole', apidata[0].userRole);
                                this.setState({ IsUserLogin: true });
                                if (this.state.isRemember === true)
                                    this.Create_Set_Cookies(UserName, Password);
                                this.setState({ login_loader: false });
                                this.CheckUserLogin();
                                

                            }
                            else {
                                let errors = this.state.errors;
                                errors.Password_E = apidata[0].message;
                                this.setState({ errors, Password_E: errors.Password_E, Password: '' })
                                this.setState({ login_loader: false });
                            }
                        }
                    }
                    catch (error) {
                    }
                }
            );
    }
    HandleRememberMe = e => {
        this.setState({
            isRemember: e.target.checked
        });
    }
    getjwt(UserName, Password) {
        userService.GetUsetLogin(UserName, Password)
        .then(
            apidata => {
                try {
                    if (apidata.length > 0) {
                        if (apidata[0].code !== "-1") {
                        }
                    }
                }
                         catch (error) {
                            }
                        }
            );
    }
    Create_Set_Cookies(username, password) {
        try {
            var CryptoJS = require("crypto-js");
            const cookies = new Cookies();
            cookies.set('_col_uuid', CryptoJS.AES.encrypt(username, 'sblw3hn8').toString(), { path: '/' });
            cookies.set('__gads', CryptoJS.AES.encrypt(password, 'sblw3hn8').toString(), { path: '/' });
        }
        catch (error) { }
    }
    callForTokenAPI(code) {
        userService.getToken(code, 'RxHckerPro')
            .then(
                apidata => {
                    try {
                        if (apidata.length > 0) {
                            this.callUserInfoAPI(apidata[0].access_token)
                        }
                        else {
                            let errors = this.state.errors;
                            errors.Password_E = 'Username or password is incorrect';
                            this.setState({ errors, Password_E: errors.Password_E, Password: '' })
                        }
                    }
                    catch (error) {
                        let errors = this.state.errors;
                        errors.Password_E = 'Username or password is incorrect';
                        this.setState({ errors, Password_E: errors.Password_E, Password: '' })
                    }
                }
            );
    }
    callUserInfoAPI(access_token) {
        userService.getTokenBased_UserInfo(access_token, 'RxHckerPro')
            .then(
                apidata => {
                    try {
                        if (apidata.length > 0) {
                            this.setState({ IsUserLogin: true });
                        }
                        else {
                            let errors = this.state.errors;
                            errors.Password_E = 'Username or password is incorrect';
                            this.setState({ errors, Password_E: errors.Password_E, Password: '' })
                        }
                    }
                    catch (error) {
                        let errors = this.state.errors;
                        errors.Password_E = 'Username or password is incorrect';
                        this.setState({ errors, Password_E: errors.Password_E, Password: '' })
                    }
                }
            );
    }
    HandleThirdParty = (e) => {
        let Clientid = "987654321";
        let my_url_redirect = "http://temp1.patientrxdiscount.com/login";
        let uri_redirect = "http://temp3.patientrxdiscount.com/Authorize.aspx";
        let redirUrl = uri_redirect + "?redirect_uri=" + my_url_redirect + "&response_type=code&client_id=" + Clientid;
        window.location.href = redirUrl;
    }
    showpassword = (event) => {
        var attr = $(event.target).next().attr('type');
        if (attr === 'text') {
            $(event.target).next().attr('type', 'password');
        }
        else {
            $(event.target).next().attr('type', 'text');
        }
        $(event.target).toggleClass('active');

    }
    keyPress = (e) => {
        if (e.keyCode === 13) {
            let errors = this.state.errors;
            if (userService.Check_Null_Empty(this.state.UserName))
                errors.UserName_E = 'Please enter Valid Username';
            if (userService.Check_Null_Empty(this.state.Password))
                errors.Password_E = 'Please enter Password';
            if (JSON.stringify(errors) !== JSON.stringify(this.noerrors))
                this.setState({ errors: errors });
            else
                this.MakeLogin(this.state.UserName, this.state.Password);
        }
    }
    removeError = (event) => {
        let errors = this.state.errors;
        errors[event.target.previousElementSibling.previousElementSibling.name + "_E"] = '';
        this.setState({ errors: errors });
    }
    dofocus = (event) => {
        event.target.previousElementSibling.focus();
    }
    CheckUserLogin() {
        try {
            if (window.opener && window.self.name === "popup") {
                if (this.state.IsUserLogin === true) {
                    let getlogin = window.location.href;
                    var wparam = localStorage.getItem('wt19:awaydpset');
                    if (getlogin.toLowerCase().includes("release-backoffice") || getlogin.toLowerCase().includes("dev-backoffice")) {
                        window.opener.location.href = 'http://dev.glichealth.com/login?q=' + wparam;
                        window.self.close();
                    }
                    else if (getlogin.toLowerCase().includes("localhost:")) {
                        window.opener.location.href = 'http://localhost:5464/login?q=' + wparam;
                        window.self.close();
                    }
                    else {
                        window.opener.location.href = 'https://reporting.nulifespanrx.com/login?q=' + wparam;
                        window.self.close();
                    }
                }
            }
        }
        catch (error) {
            console.log('Exception Error-' + error);
        }
    }
    render() {
        const { errors } = this.state;
        if (!userService.Check_Null_Empty(localStorage.getItem("ajs_user_id"))) {
            if (window.atob(localStorage.getItem('_Host_Pro_type')) !== "Admin") {
                if (window.atob(localStorage.getItem('_Host_Pro_type')) === "consumer") {
                    return <Redirect to="/consumer/summary" />
                } else {
                    return <Redirect to="/summary" />
                }                
            }
            else {
                return <Redirect to="/Admin/groups" />
            }
        }
        return (
            <div className="container_signup">
                <Signup_header />
                <div className="login_area_new_change">
                    <div className="signupbox_area">
                        <div className="image_area">
                            <img className="mobile_login_bg" src={mobile_login_bg} alt=""/>
                            <img src={Signupleft} alt=""/>
                        </div>
                        <div className="signuparea">
                            <div className="fields">
                                <p>Welcome back</p>
                                <p className={errors.UserName_E.length > 0 ? 'paaword_area errors' : 'paaword_area'}>
                                    <input type="text" placeholder=" " name="UserName" value={this.state.UserName} onChange={this.HandleInput} onKeyDown={this.keyPress} onFocus={this.HandleInput} />
                                    <span onClick={this.dofocus.bind(this)}>Username</span>
                                    {errors.UserName_E.length > 0 &&
                                        <span onClick={this.removeError.bind(this)}>{errors.UserName_E} </span>}
                                </p>
                                <p className={errors.Password_E.length > 0 ? 'paaword_area errors' : 'paaword_area'}>
                                    <i onClick={this.showpassword}></i>
                                    <input type="Password" placeholder=" " name="Password" value={this.state.Password} onChange={this.HandleInput} onKeyDown={this.keyPress} onFocus={this.HandleInput} />
                                    <span onClick={this.dofocus.bind(this)}>Password</span>
                                    {errors.Password_E.length > 0 &&
                                        <span onClick={this.removeError.bind(this)}>{errors.Password_E} </span>}


                                </p>
                                <div className="forgotpass_link">
                                    <div className="checkbox_sec">
                                        <input type="checkbox" id="remember" name="rememberMe" checked={this.state.isRemember} onChange={this.HandleRememberMe} />
                                        <label htmlFor="remember">
                                            <span></span> Remember me
                        </label>
                                    </div>
                                  {!window.opener ?
                                    <div className="forgot_pass_btn">
                                        <NavLink to="forgotpassword">
                                            Forgot Password?
                                            </NavLink>
                                    </div> : null}
                                </div>
                                <div className={this.state.login_loader ? "save_continue loader" : 'save_continue'} onClick={this.HandleButtonClick.bind(this)}>Sign In</div>
                                {/*<div className="save_continue" onClick={this.HandleThirdParty}>Sign In with third party</div>*/}
                             {!window.opener ?
                                <div className="already_link">
                                    Don’t have account? &nbsp;
                      <NavLink to="/signup">
                                        Sign Up here
                      </NavLink>
                                </div> : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default LogIn;
